import { stringify } from "qs";

import finderApi from "api/services/finder-api";
import { Coordinates } from "custom-types/Coordinates";
import { DispensariesResponse } from "custom-types/Dispensary";
import logError from "utils/logError";

type GetDispensariesParams = {
  countryCode: string;
  coordinates: Coordinates;
  filter?: string[];
  take?: number;
  maxDistance?: number;
  params?: Record<string, string>;
  includeDispensaries?: boolean;
  newDefaultSort?: string;
};

const getDispensaries = async ({
  countryCode,
  coordinates,
  filter = [],
  take = 7,
  maxDistance = 20,
  params = {},
  includeDispensaries = true,
  newDefaultSort = "default",
}: GetDispensariesParams): Promise<DispensariesResponse> => {
  try {
    const { data } = await finderApi.get(`/v3/dispensaries`, {
      headers: {
        "x-country-code": countryCode,
      },
      params: {
        filter: includeDispensaries ? ["dispensary", ...filter] : [...filter],
        geo_query_type: "point",
        lat: coordinates.lat,
        limit: take,
        lon: coordinates.lon,
        radius: `${maxDistance}mi`,
        sort_version: newDefaultSort,
        ...params,
      },
      paramsSerializer: (params) => {
        return stringify(params, { arrayFormat: "brackets" });
      },
    });

    return data;
  } catch (err) {
    logError(err.message, {
      functionName: "getDispensaries",
      service: "finder",
      statusCode: err.statusCode,
    });
    return {
      availableFilters: [],
      availableSorts: [],
      currentPage: 0,
      pageCount: 0,
      sponsoredStores: [],
      spotlight: [],
      stores: [],
      storesCount: 0,
    };
  }
};

export default getDispensaries;
