import { getServerSideSplitTreatments } from "@leafly-com/split-next";
import shuffle from "lodash/shuffle";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { JsonLd } from "react-schemaorg";
import { WebSite } from "schema-dts";

import getMarqueeCreatives from "api/requests/consumerApi/getMarqueeCreatives";
import getCampaignSlots from "api/requests/getCampaignSlots";
import getNewsletterSection from "api/requests/getNewsletterSection";
import getNewsSections from "api/requests/getNewsSections";
import getPopularCities from "api/requests/getPopularCities";
import getProductCategories from "api/requests/getProductCategories";
import { getUserLocationData } from "api/requests/getUserLocation";
import getValueProps from "api/requests/getValueProps";
import { Action, Category } from "constants/events";
import { AdMarqueePageLocation, Creative } from "custom-types/AdMarquee";
import {
  CampaignSlots,
  HomePageNewsSection,
  NewsletterSection,
  PopularCities as PopularCitiesType,
  ProductCategories,
  ValueProps as ValuePropsType,
} from "custom-types/Directus";
import { DispensaryV3 } from "custom-types/DispensariesV3";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import { determineOrderMethod } from "utils/determineOrderMethod";
import { getMerchId } from "utils/eventTracking";
import { localizeText } from "utils/localizeText";
import { getCountryCodeHeader } from "utils/requestContext";
import { getSplitKey } from "utils/split/getSplitKey";

import AdMarqueeCarousel from "components/AdMarqueeCarousel";
import DealsTakeover from "components/DealsTakeover";
import DispensariesCarousel from "components/DispensariesCarousel";
import DownloadAppCTA from "components/DownloadAppCTA";
import FeaturedBrands from "components/FeaturedBrands";
import FeaturedDispensariesSection from "components/FeaturedDispensariesSection";
import FeaturedStrains from "components/FeaturedStrains";
import HomePageCampaignSlots from "components/HomePageCampaignSlots";
import HomePageNewsCta from "components/HomePageNewsCta";
import HomePageProductCategories from "components/HomePageProductCategories";
import MetaTags from "components/MetaTags";
import Newsletter from "components/Newsletter";
import PopularCities from "components/PopularCities";
import SocialImpact from "components/SocialImpact";
import ValueProps from "components/ValueProps";

const HomePageBrandsCarousel = dynamic(
  () => import("components/HomePageBrandsCarousel"),
);

const BRANDS = [
  {
    logo: "https://s3-us-west-2.amazonaws.com/leafly-public/2DAMoKOOSbCJNHUTCK6O_STIIIZY-420-Logo_On-Black_1000x1000-20(1).jpg",
    name: "Stiiizy",
    path: "/brands/stiiizy",
  },
  {
    logo: "https://leafly-public.s3-us-west-2.amazonaws.com/brands/logos/BfaLy5tnTrGs3NyVsNMX_Leaflypic.jpg",
    name: "Jeeter",
    path: "/brands/jeeter",
  },
  {
    logo: "https://leafly-public.s3-us-west-2.amazonaws.com/brands/logos/5b1MwFRiRmS3PKQsof16_c-bite-blue-padded.png",
    name: "Cookies",
    path: "/brands/cookies",
  },
  {
    logo: "https://s3-us-west-2.amazonaws.com/leafly-public/a02xVz7QZ2ukWS9Lc7ow_ed1fbfce-4597-4615-a4b8-06b77fc2a042",
    name: "Canamo Concentrates",
    path: "/brands/canamo-concentrates",
  },
  {
    logo: "https://leafly-public.s3-us-west-2.amazonaws.com/brands/logos/c8uFJ96To64E9PkYwZa2_Untitled-1.png",
    name: "Grow Sciences",
    path: "/brands/grow-sciences",
  },
  {
    logo: "https://leafly-public.s3-us-west-2.amazonaws.com/brands/logos/YhG2XhMzROeFTExKN6ym_large_logo.jpg",
    name: "Wyld",
    path: "/brands/wyld",
  },
  {
    logo: "https://leafly-public.s3-us-west-2.amazonaws.com/brands/logos/h3vANQCR3SVp0qcmUS8F_Screen-Shot-2021-11-16-at-4.47.26-PM.png",
    name: "Pax",
    path: "/brands/pax",
  },
];

const HomePage = ({
  brandCarouselExperiment,
  campaignSlots,
  newsletterSections,
  newsSections,
  popularCities,
  productCategories,
  valueProps,
  showCBDCarouselExperiment,
  creatives,
}: {
  brandCarouselExperiment: {
    treatment: string;
    brands: typeof BRANDS;
  };
  campaignSlots: CampaignSlots<string>[];
  newsletterSections: NewsletterSection[];
  newsSections: HomePageNewsSection<string>[];
  popularCities: PopularCitiesType;
  productCategories: ProductCategories<string>[];
  valueProps: ValuePropsType;
  showCBDCarouselExperiment: boolean;
  creatives: Creative[];
}) => {
  const countryCode = useDomainCountryCode();

  const { publishEvent } = useEventTracker();

  const trackCardImpression = (slot: number, dispensary: DispensaryV3) => {
    const eventLabel = determineOrderMethod(
      !!dispensary.flags.includes("pickup"),
      !!dispensary.flags.includes("delivery"),
      dispensary.tier,
      dispensary.flags,
    );

    const merchandisingCampaignId = getMerchId(dispensary, "platinumplacement");

    publishEvent({
      action: Action.impression,
      category: Category.dispensaryCard,
      dispensaryId: dispensary.id,
      label: `slot #${slot + 1}: ${eventLabel}`,
      merchandisingCampaignId,
    });
  };

  const schemaTargetUrl =
    countryCode === "CA" ? "https://www.leafly.ca/" : "https://www.leafly.com/";

  return (
    <>
      {/* here for SEO purposes! please dont delete */}
      <h1 className="hidden">Leafly</h1>
      <JsonLd<WebSite>
        item={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          name: "Leafly",
          potentialAction: {
            "@type": "SearchAction",
            // @ts-expect-error -- This attribute is documented (https://schema.org/docs/actions.html#part-4), but not specified in the schema-dts package
            "query-input": "required name=search_term_string",
            target: `${schemaTargetUrl}shop?q={search_term_string}`,
          },
          url: schemaTargetUrl,
        }}
      />
      <MetaTags
        canonicalParams={[]}
        title="Find, order, and learn about weed | Leafly"
        description="Leafly is the leading destination to learn about, find, and order cannabis. Learn more about types of weed strains and discover marijuana dispensaries near you."
      />
      <div className="container mt-xl">
        <AdMarqueeCarousel
          pageType={AdMarqueePageLocation.HOME_PAGE}
          creatives={creatives}
        />
      </div>

      {brandCarouselExperiment.treatment === "above" && (
        <HomePageBrandsCarousel brands={brandCarouselExperiment.brands} />
      )}

      <FeaturedDispensariesSection
        className="my-xxl"
        shouldShowCBDStores={showCBDCarouselExperiment}
      />

      {brandCarouselExperiment.treatment === "below" && (
        <HomePageBrandsCarousel brands={brandCarouselExperiment.brands} />
      )}

      {productCategories.length > 0 && (
        <HomePageProductCategories
          productCategories={productCategories}
          className="border-b border-t border-light-grey pt-xl pb-lg"
        />
      )}
      <FeaturedBrands className="py-xxl" />
      <DealsTakeover
        className="mb-xxl"
        gaCategory="Homepage Deals"
        signupSource="homepage_deals"
      />
      <DispensariesCarousel
        title={`Shop ${localizeText("dispensaries", countryCode)} near you`}
        subtitle="Showing you stores near"
        linkText={`See all ${localizeText("dispensaries", countryCode)}`}
        page="home"
        changeLocation={true}
        link="/dispensaries"
        className="pb-xxl"
        trackCardImpression={trackCardImpression}
      />
      <FeaturedStrains className="py-xxl" />
      {valueProps && <ValueProps {...valueProps} className="py-xxl" />}
      <DownloadAppCTA
        eventCategory="Homepage App Feature"
        title="The Leafly app is where it’s at"
        subtitle="Over 300,000 reviews on App Store and Google Play"
        description="Your direct line to the world’s largest strain library, over
                    a million strain reviews, award-winning articles, and of
                    course, shopping weed near you."
        utmCampaign="Home"
      />
      <HomePageCampaignSlots
        campaignSlots={campaignSlots}
        className="border-b border-light-grey py-xxl"
      />
      <HomePageNewsCta
        newsSections={newsSections}
        className="border-b border-light-grey py-xxl"
      />
      <SocialImpact className="border-b border-light-grey py-xxl" />
      <Newsletter
        newsletterSections={newsletterSections}
        className="border-b border-light-grey py-xxl"
      />
      <PopularCities
        cities={popularCities?.cities}
        className="pt-xxl pb-section"
      />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const countryCode = getCountryCodeHeader(context.req.headers);

  const userLocation = await getUserLocationData(countryCode, context);

  const [
    campaignSlots,
    newsletterSections,
    newsSections,
    popularCities,
    productCategories,
    valueProps,
    creatives,
  ] = await Promise.all([
    getCampaignSlots(countryCode),
    getNewsletterSection(countryCode),
    getNewsSections(countryCode),
    getPopularCities(countryCode),
    getProductCategories(countryCode),
    getValueProps(countryCode),
    getMarqueeCreatives(
      AdMarqueePageLocation.HOME_PAGE,
      userLocation.coordinates?.lat,
      userLocation.coordinates?.lon,
    ),
  ]);

  const {
    "SHH-731_prohibitionCBDOptimization_test": {
      treatment: cbdCarouselTreatment,
    },
  } = await getServerSideSplitTreatments(
    ["SHH-731_prohibitionCBDOptimization_test"],
    getSplitKey(context),
  );

  const showCBDCarouselExperiment = cbdCarouselTreatment === "on";

  let brandCarouselExperiment = {
    brands: [] as typeof BRANDS,
    treatment: "off",
  };
  if (userLocation?.state === "Arizona") {
    const {
      "SHH-657_homeBrandsCarousel_test": { treatment },
    } = await getServerSideSplitTreatments(
      ["SHH-657_homeBrandsCarousel_test"],
      getSplitKey(context),
    );

    brandCarouselExperiment = {
      brands: shuffle(BRANDS),
      treatment,
    };
  }

  return {
    props: {
      brandCarouselExperiment,
      campaignSlots,
      creatives,
      newsSections,
      newsletterSections,
      popularCities,
      productCategories,
      showCBDCarouselExperiment,
      valueProps,
    },
  };
};

export default HomePage;
