import React, { useState } from "react";
import { trackNonInteractionEvent } from "@leafly-com/web-utils";
import Link from "next/link";
import { useSelector } from "react-redux";

import getDispensariesV3 from "api/requests/consumerApi/getDispensariesV3";
import { Action, Category } from "constants/events";
import { DispensaryV3 } from "custom-types/DispensariesV3";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import useOnComponentInView from "hooks/useOnComponentInView";
import { getCoordinates } from "redux/selectors/location";
import { getUserId } from "redux/selectors/user";
import { determineOrderMethod } from "utils/determineOrderMethod";
import { getRoundedDistance } from "utils/distance";
import formatImgixUrl from "utils/image/formatImgixUrl";
import { localizeText } from "utils/localizeText";

import Carousel from "components/botanic/Carousel";
import DispensaryCardPlaceholder from "components/botanic/DispensaryCard/DispensaryCardPlaceholder";
import NoResults from "components/NoResults";
import PickupReadinessDispensaryCard from "components/PickupReadinessDispensaryCard";
import Subtitle from "components/Subtitle";
import TrackImpression from "components/TrackImpression";

type Props = {
  cardsPerSlide?: number;
  categoryOverride?: string;
  changeLocation?: boolean;
  className?: string;
  customNoResults?: JSX.Element | false;
  impressionOverride?: boolean;
  includeDispensaries?: boolean;
  isSjei?: boolean;
  labelOverride?: string;
  link: string;
  linkText: string;
  onItemClick?: (
    dispensary: Partial<DispensaryV3>,
    merchandisingCampaignId: number | null,
    slot: number,
  ) => void | undefined;
  page: string;
  retailType?: string;
  subtitle?: string;
  title: string;
  trackCardImpression: (slot: number, dispensary: DispensaryV3) => void;
};

const DispensariesCarousel: React.FC<Props> = ({
  cardsPerSlide = 4,
  changeLocation = false,
  className = "",
  customNoResults,
  impressionOverride,
  isSjei = false,
  link,
  linkText,
  onItemClick,
  page,
  retailType,
  subtitle,
  title,
  trackCardImpression,
}) => {
  const [dispensaries, setDispensaries] = useState<
    DispensaryV3[] | Record<string, never>[]
  >([{}, {}, {}, {}]);
  const coordinates = useSelector(getCoordinates);
  const countryCode = useDomainCountryCode();
  const userId = useSelector(getUserId);

  const { publishEvent } = useEventTracker();

  const getData = async () => {
    !impressionOverride &&
      trackNonInteractionEvent(
        "Dispensaries Carousel",
        "Impression",
        "Dispensaries Carousel",
      );

    const flags: Array<string> = [];
    flags.push(countryCode);
    if (isSjei) {
      flags.push("blackOwned");
    }
    retailType ? flags.push(retailType) : flags.push("dispensary");

    const { stores, sponsoredStores } = await getDispensariesV3({
      flags: flags,
      lat: coordinates.lat,
      lon: coordinates.lon,
      radius: "20mi",
      take: 7,
    });

    sponsoredStores.forEach((_store: DispensaryV3, i: number) => {
      sponsoredStores[i].isAd = true;
    });

    const dispensariesArray = sponsoredStores.concat(stores).splice(0, 7);

    dispensariesArray.forEach((dispensary: DispensaryV3, index: number) => {
      if (dispensary.logoUrl) {
        dispensariesArray[index].logoUrl = formatImgixUrl(dispensary.logoUrl);
      }
    });

    setDispensaries(dispensariesArray);
  };

  const [ref] = useOnComponentInView(getData, {
    triggerOnce: true,
  });

  const SubtitleComponent = subtitle ? (
    <Subtitle
      title={title}
      subtitle={subtitle}
      changeLocation={changeLocation}
    />
  ) : undefined;
  const localizedTextSingular = localizeText("dispensary", countryCode);
  const localizedTextPlural = localizeText("dispensaries", countryCode);
  const NoResultsComponent = customNoResults || (
    <NoResults sectionName="dispensaries">
      <p className="font-bold text-md">{`There are no ${localizedTextPlural} in your area yet.`}</p>
      <p>
        <Link
          href="/dispensaries"
          className="font-bold no-underline text-green"
        >
          Check out our {localizedTextPlural} map
        </Link>{" "}
        to find the nearest {localizedTextSingular} outside of your area.
      </p>
    </NoResults>
  );

  return (
    <div ref={ref} className={className}>
      <Carousel
        cardsPerSlide={cardsPerSlide}
        title={title}
        subtitle={SubtitleComponent}
        seeMoreText={linkText}
        seeMoreHref={link}
        id="dispensaries-section"
        showSeeMoreCard={true}
        noResultsDisplay={NoResultsComponent}
        seeMoreOnClick={() => {
          publishEvent({
            action: Action.click,
            category: Category.carousel,
            label: `${page} dispensaries carousel show all`,
          });
        }}
        className="container"
        seeMoreCardClass="rounded overflow-hidden shadow-low"
      >
        {dispensaries.map(
          (dispensary: DispensaryV3 | Record<string, never>, slot: number) => {
            if (dispensary.name) {
              const eventLabel = determineOrderMethod(
                !!dispensary.flags.includes("pickup"),
                !!dispensary.flags.includes("delivery"),
                dispensary.tier,
                dispensary.flags,
              );
              const platinumPlacement = dispensary.merchandisedCampaigns?.find(
                (campaign) => campaign.unitName === "platinumplacement",
              );
              const merchandisingCampaignId = platinumPlacement
                ? platinumPlacement.id
                : null;
              return (
                <TrackImpression
                  className="h-full"
                  trackingFunction={() =>
                    trackCardImpression(slot, dispensary as DispensaryV3)
                  }
                  key={`dispensary-${slot}`}
                >
                  <PickupReadinessDispensaryCard
                    preserveHeight
                    currentStatus={dispensary.currentStatuses?.store}
                    deliveryEnabled={!!dispensary.flags.includes("delivery")}
                    distanceMi={dispensary.distanceMi}
                    hideCTA
                    dispensaryId={dispensary.id}
                    inStoreCartEnabled={dispensary.inStoreCartEnabled}
                    isAd={dispensary.isAd}
                    isNewDispensary={dispensary.flags.includes(
                      "new_dispensary",
                    )}
                    logoUrl={dispensary.logoUrl}
                    name={dispensary.name}
                    pickupEnabled={!!dispensary.flags.includes("pickup")}
                    preorderConfigured={
                      !!dispensary.flags.includes("preorder_configured")
                    }
                    onClick={() => {
                      if (onItemClick) {
                        onItemClick(dispensary, merchandisingCampaignId, slot);
                      } else {
                        publishEvent({
                          action: Action.click,
                          category: Category.dispensaryCard,
                          dispensaryDistance: getRoundedDistance(
                            dispensary.distanceMi,
                            countryCode,
                          ),
                          dispensaryId: dispensary.id,
                          label: `slot #${slot + 1}: ${eventLabel}`,
                          merchandisingCampaignId:
                            merchandisingCampaignId || undefined,
                          userId,
                        });
                      }
                    }}
                    rating={dispensary.starRating}
                    reviewCount={dispensary.reviewCount}
                    scheduleType="store"
                    slug={dispensary.slug}
                    tags={dispensary.flags}
                    timeZone={dispensary.timeZone}
                    data-testid="dispensaries-section-card"
                  />
                </TrackImpression>
              );
            } else {
              return (
                <DispensaryCardPlaceholder
                  id={`dispensary-card-placeholder-${slot}`}
                  key={slot}
                  hideCTA
                />
              );
            }
          },
        )}
      </Carousel>
    </div>
  );
};

export default DispensariesCarousel;
