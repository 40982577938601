import React, { useEffect, useState } from "react";
import { useClientSideSplitTreatments } from "@leafly-com/split-next";
import axios from "axios";
import { useSelector } from "react-redux";

import getFeaturedDispensaries from "api/requests/consumerApi/getFeaturedDispensaries";
import getShoppableDispensariesNearby from "api/requests/consumerApi/getShoppableDispensariesNearby";
import getDispensaries from "api/requests/getDispensaries";
import { Action, Category } from "constants/events";
import { Dispensary } from "custom-types/Dispensary";
import { FeaturedDispensary } from "custom-types/FeaturedDispensary";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import { getCoordinates } from "redux/selectors/location";
import { getUserId } from "redux/selectors/user";
import convertDispToFeaturedDisp from "utils/convertDispToFeaturedDisp";
import { getRoundedDistance } from "utils/distance";
import { localizeText } from "utils/localizeText";
import { getSplitKey } from "utils/split/getSplitKey";

import Carousel from "components/botanic/Carousel";
import DispensaryCardPlaceholder from "components/botanic/DispensaryCard/DispensaryCardPlaceholder";
import PickupReadinessDispensaryCard from "components/PickupReadinessDispensaryCard";
import Subtitle from "components/Subtitle";
import TrackImpression from "components/TrackImpression";

const DISPENSARY_CARD_VARIANT = "platinum";

type Props = {
  className?: string;
  shouldShowCBDStores?: boolean;
};

const FeaturedDispensariesSection = ({
  className = "",
  shouldShowCBDStores,
}: Props) => {
  const [featuredDispensaries, setFeaturedDispensaries] = useState([
    {},
    {},
    {},
    {},
  ] as FeaturedDispensary[]);
  const { publishEvent, trackEvent, trackNonInteractionEvent } =
    useEventTracker();

  const countryCode = useDomainCountryCode();
  const coordinates = useSelector(getCoordinates);
  const userId = useSelector(getUserId);

  const backfillDispensaries = async (
    featuredDispensaries: FeaturedDispensary[],
    limit = 6,
  ) => {
    const hasLocation = coordinates?.lat && coordinates?.lon;
    const shouldFill =
      featuredDispensaries.length < limit || // if there's less than the limit, we need to fill
      Object.keys(featuredDispensaries[limit - 1]).length === 0; // if the last dispensary is empty, we need to fill
    if (hasLocation && shouldFill) {
      //new backfill results set shown when shouldShowCBDStores flag is enabled

      const backfillResults = await getShoppableDispensariesNearby(coordinates);

      // old backfill results when shouldShowCBDStores flag is disabled
      const dispensaries = await getDispensaries({
        coordinates,
        countryCode,
      });
      const { sponsoredStores = [], stores = [] } = dispensaries;

      const backfillStores = sponsoredStores.concat(stores);

      const backfillLimit = limit - featuredDispensaries.length;

      const oldDispensariesToAdd = backfillStores.reduce(
        (additionalFeaturedDispensaries, store) => {
          // Don't add dispensaries that are already in the featuredDispensaries
          if (
            !featuredDispensaries.some(
              (dispensary) => dispensary?.id === store.id,
            ) &&
            additionalFeaturedDispensaries.length < backfillLimit
          ) {
            // normalize from Dispensary to FeaturedDispensary
            additionalFeaturedDispensaries.push(
              convertDispToFeaturedDisp(store),
            );
          }
          return additionalFeaturedDispensaries;
        },
        [] as FeaturedDispensary[],
      );
      const newDispensariesToAdd: FeaturedDispensary[] = backfillResults.reduce(
        (
          additionalFeaturedDispensaries: FeaturedDispensary[],
          store: Dispensary,
        ) => {
          // Don't add dispensaries that are already in the featuredDispensaries
          if (
            !featuredDispensaries.some(
              (dispensary) => dispensary?.id === store.id,
            ) &&
            additionalFeaturedDispensaries.length < backfillLimit
          ) {
            additionalFeaturedDispensaries.push(
              convertDispToFeaturedDisp(store),
            );
          }

          return additionalFeaturedDispensaries as FeaturedDispensary[];
        },
        [] as FeaturedDispensary[],
      );

      if (shouldShowCBDStores) {
        return featuredDispensaries.concat(
          newDispensariesToAdd as unknown as FeaturedDispensary[],
        );
      }

      return featuredDispensaries.concat(
        oldDispensariesToAdd as FeaturedDispensary[],
      );
    } else {
      return featuredDispensaries;
    }
  };

  useEffect(() => {
    if (coordinates?.lat && coordinates?.lon) {
      getFeaturedDispensaries(coordinates, countryCode).then(
        async (dispensaries) => {
          const sponsoredStoresWithAdTag = await dispensaries.map(
            (dispensary) => {
              return {
                ...dispensary,
                isAd: true,
              };
            },
          );
          setFeaturedDispensaries(
            await backfillDispensaries(sponsoredStoresWithAdTag),
          );
        },
      );
    } else {
      setFeaturedDispensaries([]);
    }
  }, []);

  const { treatments, isLoading } = useClientSideSplitTreatments(
    "SHH-735_dispensaryCardLinks_test",
    getSplitKey(),
  );

  const shouldSwapNavLinks =
    !isLoading &&
    treatments["SHH-735_dispensaryCardLinks_test"]?.treatment === "on";

  return featuredDispensaries?.length ? (
    <>
      <TrackImpression
        category="Featured Dispensaries Carousel"
        label="Featured Dispensaries Carousel"
        data-testid="featured-dispensaries-section"
        className={className}
      >
        <div className="container">
          <Carousel
            title={
              shouldShowCBDStores ? "Shops near you" : "Local dispensaries"
            }
            subtitle={
              shouldShowCBDStores ? (
                <Subtitle
                  title="Shops near you"
                  subtitle="Showing you stores near"
                  changeLocation
                />
              ) : (
                `Order online from our sponsored ${
                  countryCode === "US" ? "partners." : "stores."
                }`
              )
            }
            seeMoreText={`See all ${localizeText("dispensaries", countryCode)}`}
            seeMoreHref="/dispensaries"
            showSeeMoreCard={true}
            seeMoreOnClick={() => {
              trackEvent(
                Category.Carousel,
                Action.click,
                "home featured dispensaries carousel show all",
              );
            }}
            seeMoreCardClass="rounded overflow-hidden shadow-low"
          >
            {featuredDispensaries.map((dispensary, slot) => {
              if (dispensary.name) {
                const deliveryEnabled =
                  !!dispensary.deliveryEnabled ||
                  !!dispensary.hasDeliveryEnabled ||
                  Boolean(dispensary.currentStatuses?.delivery);
                const pickupEnabled =
                  !!dispensary.pickupEnabled ||
                  Boolean(dispensary.currentStatuses?.pickup);

                const customDimensions = {
                  deal_id: dispensary.featuredDeal?.id,
                  dispensaryDistance: getRoundedDistance(
                    dispensary.distanceMi,
                    countryCode,
                  ),
                  dispensaryId: dispensary.id,
                  merchandisingCampaignId: dispensary.merchandisingCampaignId,
                  userId: userId as number,
                };

                const impressionLabel = `dispensary card`;

                const eventOnClick = () => {
                  publishEvent({
                    action: Action.click,
                    category: Category.homepageFeature,
                    label: impressionLabel,
                    ...customDimensions,
                  });
                };

                const trackCtaClick = () => {
                  publishEvent({
                    action: Action.click,
                    category: Category.homepageFeature,
                    label: `${impressionLabel} button`,
                    ...customDimensions,
                  });
                };

                return (
                  <TrackImpression
                    category={Category.homepageFeature}
                    className="h-full w-full relative"
                    key={`featured-dispensary-${slot}`}
                    label={impressionLabel}
                    trackingFunction={() => {
                      trackNonInteractionEvent(
                        Category.homepageFeature,
                        Action.impression,
                        impressionLabel,
                        {
                          deal_id: dispensary.featuredDeal?.id,
                          dispensaryDistance: getRoundedDistance(
                            dispensary.distanceMi,
                            countryCode,
                          ),
                          dispensaryId: dispensary.id,
                          merchandisingCampaignId:
                            dispensary.merchandisingCampaignId,
                          userId: userId as number,
                        },
                      );
                      // this is for kevel impression tracking, ask kayleigh
                      if (dispensary.impressionUrl) {
                        axios.get(dispensary.impressionUrl);
                      }
                    }}
                  >
                    <PickupReadinessDispensaryCard
                      coverPhotoSizes={[196, null, null, 207, null, 207]}
                      ctaOnClick={trackCtaClick}
                      currentStatus={dispensary.currentStatuses?.store}
                      data-testid={`featured-dispensaries-section-card-${dispensary.id}`}
                      deal={dispensary.featuredDeal}
                      deliveryEnabled={deliveryEnabled}
                      disableLazyLoad={true}
                      dispensaryId={dispensary.id}
                      distanceMi={dispensary.distanceMi}
                      flags={dispensary.flags}
                      href={dispensary.href}
                      inStoreCartEnabled={dispensary.inStoreCartEnabled}
                      isAd={dispensary.isAd}
                      logoUrl={dispensary.logoUrl}
                      name={dispensary.name}
                      onClick={eventOnClick}
                      photoUrl={dispensary.coverPhotoUrl}
                      pickupEnabled={pickupEnabled}
                      preorderConfigured={dispensary.preorderConfigured}
                      preserveHeight={true}
                      rating={dispensary.starRating || dispensary.rating}
                      reviewCount={dispensary.reviewCount}
                      scheduleType="store"
                      shouldSwapNavLinks={shouldSwapNavLinks}
                      slug={dispensary.slug}
                      tags={dispensary.tags}
                      timeZone={dispensary.timeZone}
                      variant={DISPENSARY_CARD_VARIANT}
                    />
                  </TrackImpression>
                );
              } else {
                return (
                  <DispensaryCardPlaceholder
                    key={slot}
                    variant={DISPENSARY_CARD_VARIANT}
                    id={`featured-dispensary-card-placeholder-${slot}`}
                  />
                );
              }
            })}
          </Carousel>
        </div>
      </TrackImpression>
    </>
  ) : null;
};

export default FeaturedDispensariesSection;
