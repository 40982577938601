import { Dispensary } from "../custom-types/Dispensary";
import { FeaturedDispensary } from "../custom-types/FeaturedDispensary";
import formatImgixUrl from "./image/formatImgixUrl";

export const convertDispToFeaturedDisp = (store: Dispensary) => {
  const logo = store.logoUrl || store.logoImage || "";
  return {
    ...store,
    logoUrl: logo ? formatImgixUrl(logo) : "",
    rating: store.starRating,
    reviewCount: store.numberOfReviews,
    tags: store.flags || [],
  } as FeaturedDispensary;
};

export default convertDispToFeaturedDisp;
