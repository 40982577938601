import consumerApi from "api/services/consumer-api";
import { Coordinates } from "custom-types/Coordinates";
import { FeaturedDispensary } from "custom-types/FeaturedDispensary";
import formatImgixUrl from "utils/image/formatImgixUrl";
import logError from "utils/logError";

const getFeaturedDispensaries = async (
  coordinates: Coordinates,
  countryCode = "US",
) => {
  try {
    const featuredDispensaries = await consumerApi.get<FeaturedDispensary[]>(
      "/api/dispensaries/v1/homepage_featured",
      {
        headers: {
          "x-country-code": countryCode,
        },
        params: {
          lat: coordinates.lat,
          lon: coordinates.lon,
          platform: "web",
        },
      },
    );

    if (featuredDispensaries?.data?.length) {
      featuredDispensaries.data.forEach((dispensary: FeaturedDispensary) => {
        if (dispensary.logoUrl) {
          dispensary.logoUrl = formatImgixUrl(dispensary.logoUrl);
        }

        if (dispensary.coverPhotoUrl) {
          dispensary.coverPhotoUrl = formatImgixUrl(dispensary.coverPhotoUrl);
        }
      });
      return featuredDispensaries.data;
    } else {
      return [];
    }
  } catch (e) {
    logError(e.message, {
      functionName: "getFeaturedDispensaries",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return [];
  }
};

export default getFeaturedDispensaries;
